import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import Shards from './Shards';
import Logo from './Logo';
import { H6 } from '../../common/Typography';

import useWaypoint from '../../../hooks/useWaypoint';
import { fadeInDown } from '../../../utils/transitions';
import { minWidth, maxWidth, maxHeight } from '../../../utils/breakpoints';
import { store } from '../../../context/store';

const Wrapper = styled(Grid)`
  background: ${({ theme }) => theme.colors.black};
  pointer-events: none;
  z-index: -5;
  user-select: none;
  overflow: hidden;
  height: -webkit-fill-available;
  grid-template-rows: 100vh;
`;

const EdgeLogo = styled(Logo)`
  grid-row: 1;
  align-self: center;
  pointer-events: none;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col / span 11;
  }
`;

const ScrollInstruction = styled(H6)`
  grid-column: col / span 12;
  grid-row: 1;
  align-self: end;
  position: relative;
  font-family: Aeonik, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  justify-self: end;
  writing-mode: vertical-rl;
  ${maxHeight('minHeroHeight')} {
    display: none;
  }
  ${maxWidth('tabletLandscape')} {
    bottom: 12vh;
  }
  ${minWidth('tabletLandscape')} {
    bottom: 4vw;
  }
`;

export default function Hero({ className, scrollInstruction, images }) {
  const [ref, visible] = useWaypoint({ threshold: 0 });
  const { heroAnimated } = useContext(store).state;

  return (
    <Wrapper ref={ref} className={className}>
      <Shards images={images} />
      <ScrollInstruction as="p" css={fadeInDown(heroAnimated && visible, 3)}>
        {scrollInstruction}
      </ScrollInstruction>
      <EdgeLogo active={heroAnimated && visible} />
    </Wrapper>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array.isRequired,
  scrollInstruction: PropTypes.string.isRequired,
};
