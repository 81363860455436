import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useScrollPercentage } from 'react-scroll-percentage';
import IntLink from '../../common/IntLink';
import ArrowLink from '../../common/ArrowLink';
import Grid from '../../common/Grid';
import ScaleOnHover from '../../common/ScaleOnHover';
import { H2, H5 } from '../../common/Typography';
import VerticalPan from '../../common/VerticalPan';
import Unclip from '../../common/Unclip';
import Video from '../../common/Video';

import { maxWidth, minWidth } from '../../../utils/breakpoints';
import useWaypoint from '../../../hooks/useWaypoint';
import { fadeIn } from '../../../utils/transitions';
import withComplimentaryColors from '../../common/withComplimentaryColors';

const Wrapper = styled(Grid)`
  position: relative;
  align-items: start;
  align-items: center;
`;

const Meta = styled.div`
  display: grid;
  grid-row-gap: inherit;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
    grid-row: 2;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col / span 6;
  }
`;

const PanWrapper = styled(VerticalPan)`
  ${maxWidth('tabletLandscape')} {
    padding-bottom: 60%;
  }
  ${minWidth('tabletLandscape')} {
    padding-bottom: 128.846153846%;
  }
`;

const ImgWrapper = styled(IntLink)`
  display: block;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 8 / span 5;
    grid-row: 1;
  }
`;

const MobileImage = styled(Unclip)`
  ${minWidth('tabletLandscape')} {
    display: none;
  }
`;

const DesktopImage = styled(Unclip)`
  ${maxWidth('tabletLandscape')} {
    display: none;
  }
`;

const VideoWrapper = styled(IntLink)`
  grid-column: col 7 / span 3;
  grid-row: 1;
  position: relative;
  align-self: end;
  bottom: 0px;
  video > {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  }
  ${maxWidth('tabletLandscape')} {
    display: none;
  }
`;

function Showcase({
  backgroundColor,
  className,
  cta,
  desktopImage,
  mobileImage,
  projectName,
  slug,
  text,
  title,
  video,
}) {
  const [waypoint, visible] = useWaypoint();
  const [wrapper, percentage] = useScrollPercentage();
  return (
    <Wrapper
      ref={waypoint}
      as="article"
      paddedTop
      paddedBottom
      className={className}
      backgroundColor={backgroundColor}
    >
      <Meta ref={wrapper}>
        <H2 css={fadeIn(visible, 2)} as="h2">
          {title}
        </H2>
        <H5 css={fadeIn(visible, 2)} as="p">
          {text}
        </H5>
        <ArrowLink css={fadeIn(visible, 2)} to={`/projects/${slug}/`}>
          {cta}
        </ArrowLink>
      </Meta>
      <ImgWrapper to={`/projects/${slug}/`}>
        <ScaleOnHover>
          <PanWrapper>
            <MobileImage direction="left" active={visible} order={1}>
              <GatsbyImage image={mobileImage} alt={projectName} />
            </MobileImage>
            <DesktopImage direction="down" active={visible} order={1}>
              <GatsbyImage image={desktopImage} alt={projectName} />
            </DesktopImage>
          </PanWrapper>
        </ScaleOnHover>
      </ImgWrapper>
      <VideoWrapper
        to={`/projects/${slug}/`}
        style={{ transform: `translateY(${percentage * -62}%)` }}
      >
        <ScaleOnHover>
          <Unclip direction="right" active={visible} order={2}>
            <Video src={video} />
          </Unclip>
        </ScaleOnHover>
      </VideoWrapper>
    </Wrapper>
  );
}

export default withComplimentaryColors(Showcase);

Showcase.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  mobileImage: PropTypes.object.isRequired,
  desktopImage: PropTypes.object.isRequired,
  projectName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};
