import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import SplitLines from '../../common/SplitLines';
import { H3 } from '../../common/Typography';

import { maxWidth, minWidth } from '../../../utils/breakpoints';
import useWaypoint from '../../../hooks/useWaypoint';

const Text = styled.div`
  display: grid;
  grid-row-gap: inherit;
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 10;
  }
`;

export default function Bridge({ className, text }) {
  const [ref, visible] = useWaypoint({ threshold: 0.9 });
  return (
    <Grid className={className} ref={ref} paddedTop>
      <Text>
        <H3 as="p">
          <SplitLines visible={visible}>{text}</SplitLines>
        </H3>
      </Text>
    </Grid>
  );
}

Bridge.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};
