import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '../../common/Grid';
import VideoPlayer from '../../common/VideoPlayer/index';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import fluidRange from '../../../utils/fluidRange';

const Wrapper = styled(Grid)`
  ${fluidRange({
    prop: 'padding-top',
    fromSize: '40px',
    toSize: '110px',
  })};
`;

const Video = styled.div`
  grid-column: col / span 12;
`;

export default function Showreel({ className, videoId }) {
  const [ref, visible] = useWaypoint({ threshold: 0.2 });
  return (
    <Wrapper className={className} ref={ref} paddedBottom>
      <Video ref={ref} css={fadeIn(visible)}>
        <VideoPlayer videoId={videoId} />
      </Video>
    </Wrapper>
  );
}

Showreel.propTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};
