import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ArrowLink from '../../common/ArrowLink';
import Grid from '../../common/Grid';
import SplitLines from '../../common/SplitLines';
import { H3 } from '../../common/Typography';

import fluidRange from '../../../utils/fluidRange';
import { maxWidth, minWidth } from '../../../utils/breakpoints';
import { fadeIn } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  ${fluidRange({
    prop: 'padding-top',
    fromSize: '40px',
    toSize: '110px',
  })};
`;

const Text = styled.div`
  display: grid;
  grid-row-gap: inherit;
  ${maxWidth('tabletPortrait')} {
    grid-column: col / span 12;
  }
  ${minWidth('tabletPortrait')} {
    grid-column: col / span 10;
  }
`;

export default function Introduction({ className, text, ctaLink, ctaText }) {
  const [ref, visible] = useWaypoint();
  return (
    <Wrapper className={className} ref={ref}>
      <Text>
        <H3 as="p">
          <SplitLines visible={visible}>{text}</SplitLines>
        </H3>
        {ctaLink && ctaText && (
          <ArrowLink css={fadeIn(visible, 5)} to={ctaLink}>
            {ctaText}
          </ArrowLink>
        )}
      </Text>
    </Wrapper>
  );
}

Introduction.propTypes = {
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};
