import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Grid from '../../common/Grid';
import UnstyledButton from '../../common/UnstyledButton';
import UnstyledList from '../../common/UnstyledList';
import { H2, H5, H6 } from '../../common/Typography';
import Unclip from '../../common/Unclip';

import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { duration, fadeIn, fadeInRight } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';
import fluidRange from '../../../utils/fluidRange';

const Wrapper = styled(Grid)`
  overflow: hidden;
`;

const Title = styled(H6)`
  grid-column: col / span 12;

  ${maxWidth('tabletLandscape')} {
    margin-bottom: 60px;
  }
  ${maxWidth('footerTablet')} {
    margin-bottom: 40px;
  }
`;

const List = styled(UnstyledList)`
  position: relative;
  z-index: 3;
  display: grid;
  grid-row-gap: 0.5em;
  ${maxWidth('tabletLandscape')} {
    grid-column: col / span 9;
    grid-row: 2;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 1 / span 7;
    grid-row: 2;
  }
`;

const Button = styled(H2)`
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.greyDark};
  transition: color ${duration / 2}s ease, transform ${duration / 2}s ease;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.025);
    }
  }
  ${minWidth('footerTablet')} {
    white-space: nowrap;
  }
  ${fluidRange({
    prop: 'font-size',
    fromSize: '50px',
    toSize: '220px',
  })};
`;

const ImgWrapper = styled(Unclip)`
  ${maxWidth('tabletLandscape')} {
    grid-column: col 7 / span 6;
    grid-row: 2;
    top: -60px;
  }
  ${maxWidth('footerTablet')} {
    top: -40px;
  }
  ${minWidth('tabletLandscape')} {
    position: relative;
    z-index: 2;
    grid-row: 2;
    align-self: end;
    grid-column: col 8 / span 5;
  }
`;

const Description = styled(H5)`
  margin: 0;
  line-height: 1.233333333em;
  grid-row: 3;
  ${maxWidth('footerTablet')} {
    grid-column: col 1 / span 12;
  }
  ${minWidth('footerTablet')} {
    grid-column: col 1 / span 10;
  }
  ${minWidth('tabletLandscape')} {
    grid-column: col 9 / span 4;
    position: relative;
    z-index: 2;
    grid-row: 2;
    text-align: right;
    font-size: 16px !important;
  }
  ${minWidth('desktop')} {
    font-size: 20px !important;
  }
`;

export default function Services({ className, items, title }) {
  const [ref, visible] = useWaypoint();
  const [active, setActive] = useState(null);

  useMemo(() => visible && setActive(0), [visible]);

  return (
    <Wrapper ref={ref} className={className} paddedTop paddedBottom>
      <Title css={fadeIn(visible)} as="h2">
        {title}
      </Title>
      {items.map((offering, i) => (
        <Fragment key={i}>
          <ImgWrapper direction="left" active={active === i} order={5}>
            <GatsbyImage
              image={offering.image.asset.gatsbyImageData}
              alt={offering.name}
            />
          </ImgWrapper>
          <Description
            as="p"
            active={active === i}
            css={fadeIn(active === i, 3)}
          >
            {offering.description}
          </Description>
        </Fragment>
      ))}
      <List>
        {items.map((offering, i) => (
          <li css={fadeInRight(visible, i + 1)} key={i}>
            <Button
              as={UnstyledButton}
              active={active === i}
              onClick={() => setActive(i)}
            >
              {offering.name}
            </Button>
          </li>
        ))}
      </List>
    </Wrapper>
  );
}

Services.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
