import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/common/Seo';
import localize from '../components/common/localize';
import PreFooter from '../components/common/PreFooter';
import Hero from '../components/pages/home/Hero';
import Bridge from '../components/pages/home/Bridge';
import Introduction from '../components/pages/home/Introduction';
import Showreel from '../components/pages/home/Showreel';
import Showcase from '../components/pages/home/Showcase';
import Services from '../components/pages/home/Services';

function Index({ data }) {
  const { page, settings } = data;

  return (
    <>
      <SEO title={settings.siteTitle} description={page.description} />
      <Hero
        scrollInstruction={page.scrollInstruction}
        images={page.heroImages}
      />
      <Introduction
        text={page.introduction}
        ctaLink="/about/"
        ctaText={page.aboutLink}
      />
      <Showreel videoId={page.showreelURL} />
      {page.showcases.map((showcase, i) => (
        <Showcase
          key={i}
          title={showcase.title}
          text={showcase.text}
          cta={showcase.CTA}
          video={showcase.video.asset.url}
          backgroundColor={showcase.caseStudy.color.hex}
          mobileImage={showcase.caseStudy.mobileImage.asset.gatsbyImageData}
          desktopImage={showcase.caseStudy.desktopImage.asset.gatsbyImageData}
          slug={showcase.caseStudy.slug.current}
          projectName={showcase.caseStudy.name}
        />
      ))}
      <Bridge text={page.bridge} />
      <Services title={page.servicesTitle} items={page.services} />
      <PreFooter />
    </>
  );
}

export default localize(Index);

export const query = graphql`
  query {
    settings: sanitySettings {
      siteTitle {
        localized
      }
    }
    page: sanityHome {
      title {
        localized
      }
      description {
        localized
      }
      heroImages {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      scrollInstruction {
        localized
      }
      introduction {
        localized
      }
      aboutLink {
        localized
      }
      showreelURL
      showcases {
        text {
          localized
        }
        title {
          localized
        }
        video {
          asset {
            url
          }
        }
        CTA {
          localized
        }
        caseStudy {
          color {
            hex
          }
          name
          slug {
            current
          }
          mobileImage: image {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1000
                height: 750
                placeholder: BLURRED
              )
            }
          }
          desktopImage: image {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 650
                height: 1037
                placeholder: BLURRED
              )
            }
          }
        }
      }
      bridge {
        localized
      }
      servicesTitle {
        localized
      }
      services {
        name {
          localized
        }
        description {
          localized
        }
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 700, height: 467)
          }
        }
      }
    }
  }
`;

Index.propTypes = {
  data: PropTypes.object.isRequired,
};
