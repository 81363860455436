import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { HomeHero } from '../../common/Typography';
import MaskAnimation from '../../common/MaskAnimation';
import { duration } from '../../../utils/transitions';

import LocaleContext from '../../../context/LocaleContext';

const Wrapper = styled(HomeHero)`
  white-space: pre;
  align-self: center;
  text-shadow: 0 0 0.025em rgba(0, 0, 0, 0.1);
`;

function Logo({ className, active, theme, ...props }) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const { locale } = useContext(LocaleContext);
  const translations = {
    en: '3D Visualization Studio',
    fr: 'Studio Visualisation 3D',
  };
  const title = translations[locale.id];
  const segments = title.split(' ');
  return (
    <Wrapper
      shadow={hasAnimated}
      className={className}
      {...props}
      aria-label={title}
    >
      <MaskAnimation aria-hidden="true" active={active} delay={duration}>
        {segments[0]}&nbsp;
      </MaskAnimation>
      <MaskAnimation aria-hidden="true" active={active} delay={1 + duration}>
        {segments[1]}
        &nbsp;
      </MaskAnimation>
      <MaskAnimation
        aria-hidden="true"
        active={active}
        delay={2 + duration}
        onTransitionEnd={() => setHasAnimated(true)}
      >
        {segments[2]}
      </MaskAnimation>
    </Wrapper>
  );
}

export default withTheme(Logo);

Logo.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object.isRequired,
};
