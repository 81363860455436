import React, { useRef, useEffect, createRef, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { gsap, Power0, Power2 } from 'gsap';
import { GatsbyImage } from 'gatsby-plugin-image';

import useWaypoint from '../../../hooks/useWaypoint';
import useWindowSize from '../../../hooks/useWindowSize';
import { minWidth, maxWidth } from '../../../utils/breakpoints';
import { duration } from '../../../utils/transitions';
import { store } from '../../../context/store';

const Wrapper = styled.div`
  grid-row: 1;
  grid-column: full-bleed;
  position: relative;
  perspective: 1000px;
  pointer-events: none;
`;

const Shard = styled.div`
  clip-path: ${({ path }) => path};
  opacity: 0;
  position: absolute;
  ${maxWidth('tabletPortrait')} {
    &:nth-of-type(1) {
      width: 50%;
      right: 10%;
    }
    &:nth-of-type(2) {
      width: 66%;
      left: -2%;
    }
    &:nth-of-type(3) {
      width: 75%;
      right: -5%;
    }
  }
  ${minWidth('tabletPortrait')} {
    &:nth-of-type(1) {
      width: 36%;
      left: -2%;
    }
    &:nth-of-type(2) {
      width: 33%;
      right: 15%;
    }
    &:nth-of-type(3) {
      width: 62%;
      right: -5%;
    }
  }
`;

const ClippedImage = styled(GatsbyImage)`
  clip-path: ${({ path }) => path};
  overflow: auto;
`;

const imageSettings = [
  {
    from: { rotationX: -60, rotationY: -40 },
    to: { rotationX: 65, rotationY: 40 },
    clipPath: 'polygon(82% 22%, 100% 100%, 22% 86%, 0 0)',
    offset: 0.1,
  },
  {
    from: { rotationX: -75, rotationY: 50 },
    to: { rotationX: 80, rotationY: -50 },
    clipPath: 'polygon(33% 15%, 100% 0, 66% 85%, 0% 100%)',
    offset: 0.4,
  },
  {
    from: { rotationX: -60, rotationY: 40 },
    to: { rotationX: 90, rotationY: -40 },
    clipPath: 'polygon(33% 15%, 100% 0, 66% 85%, 0% 100%)',
    offset: 0.7,
  },
];

export default function Shards({ className, images, speed = 20, ...props }) {
  const { dispatch } = useContext(store);
  const [$wrapper, visible] = useWaypoint({
    threshold: 0,
    triggerOnce: false,
  });
  const intro = useRef();
  const loop = useRef([...Array(images.length)].map(() => createRef()));
  const windowSize = useWindowSize();
  const shardRefs = useRef([...Array(images.length)].map(() => createRef()));

  useEffect(() => {
    const shards = shardRefs.current.map((shard) => shard.current);
    intro.current = gsap
      .timeline({ paused: true, delay: 0.2 })
      .set(shards, {
        opacity: 1,
        scale: 0,
        rotation: gsap.utils.random(-10, 10),
        rotationX: gsap.utils.random(-10, 10),
        rotationY: gsap.utils.random(-10, -10),
      })
      .to(shards, duration * 2, {
        scale: 1,
        rotation: 0,
        rotationX: 0,
        rotationY: 0,
        ease: Power2.easeOut,
        stagger: 0.1,
      })
      .call(() => dispatch({ type: 'heroAnimated' }), null, duration / 2);

    shards.forEach((shard, index) => {
      (loop.current[index].current = gsap
        .timeline({ paused: true, repeat: -1 })
        .fromTo(
          shard,
          speed,
          {
            y: 0,
            yPercent: -100,
            rotationX: gsap.utils.random(
              imageSettings[index].from.rotationX - 2,
              imageSettings[index].from.rotationX + 2
            ),
            rotationY: gsap.utils.random(
              imageSettings[index].from.rotationY - 2,
              imageSettings[index].from.rotationY + 2
            ),
          },
          {
            y: windowSize.height,
            yPercent: 50,
            rotationX: gsap.utils.random(
              imageSettings[index].to.rotationX - 2,
              imageSettings[index].to.rotationX + 2
            ),
            rotationY: gsap.utils.random(
              imageSettings[index].to.rotationY - 2,
              imageSettings[index].to.rotationY + 2
            ),
            ease: Power0.easeNone,
          }
        )).progress(imageSettings[index].offset);
    });
  }, [windowSize.width, windowSize.height, dispatch, speed]);

  useEffect(() => {
    if (visible) {
      intro.current.play();
      loop.current.forEach((tl) => tl.current.play());
    } else {
      intro.current.pause();
      loop.current.forEach((tl) => tl.current.pause());
    }
  }, [visible]);

  return (
    <Wrapper ref={$wrapper} className={className} {...props}>
      {images.map((image, i) => (
        <Shard
          key={i}
          ref={shardRefs.current[i]}
          path={imageSettings[i].clipPath}
        >
          <ClippedImage
            image={image.asset.gatsbyImageData}
            alt=""
            placeholder="none"
          />
        </Shard>
      ))}
    </Wrapper>
  );
}

Shards.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array.isRequired,
  speed: PropTypes.number,
};
